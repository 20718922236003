import {createContext} from "react";

export const PopupContext = createContext<any>({})

export const NotificationContext = createContext<{
	shouldDisplayNotification: boolean,
	notificationTitle: string,
	notificationSubtitle: string,
	notificationType: string,
	displayNotification: (type: 'error' | 'default' | 'warning', title: string, subtitle: string) => void
}>({
	displayNotification: (title, subtitle, type) => {},
	shouldDisplayNotification: false,
	notificationTitle: '',
	notificationSubtitle: '',
	notificationType: 'default',
});

export default NotificationContext;
