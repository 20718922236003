import React from 'react';
import styled from "styled-components";
import Card from './Card'
import FirstCardImg from '../../icons/HowItWorkPage/firstCard'
import SecondCardImg from '../../icons/HowItWorkPage/secondCard'
import ThirdCardImg from "../../icons/HowItWorkPage/thirdCard";
import FourthCardImg from "../../icons/HowItWorkPage/fourthCard";
import FifthCardImg from "../../icons/HowItWorkPage/fifthCard";

const Wrapper = styled.div`
  max-width: 1920px;
  padding: 70px 120px;
  background: #E1DFE1;
  @media screen and (max-width: 1400px) {
   padding: 70px 20px;
  }
`

const Title = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 92px;
  line-height: 115px;
  text-transform: uppercase;
  color: #040611;
  margin-bottom: 45px;
  
  @media screen and (max-width: 1400px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
  }
`

const Text = styled.span`
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: #000000;
  z-index: 1;
  position: relative;
`

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  
  @media screen and (max-width: 1400px) {
    justify-content: center;
  }
`

const FirstImgWrapper  = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
`

const SecondImgWrapper  = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0;
`

const ThirdImgWrapper  = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  z-index: 0;
`

const FourthImgWrapper  = styled.div`
  position: absolute;
  bottom: 43px;
  left: 0;
`

const FifthImgWrapper  = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
`
const HowItWork = () => {
  return (
    <Wrapper>
      <Title>How it works?</Title>
      <CardsWrapper>
        <Card cardTitle={'50K'}>
          <Text>Buy 50k WOW tokens</Text>
          <FirstImgWrapper>
            <FirstCardImg />
          </FirstImgWrapper>
        </Card>
        <Card cardTitle={'LOCK TIME'}>
          <Text>Choose lock time</Text>
          <SecondImgWrapper>
            <SecondCardImg />
          </SecondImgWrapper>
        </Card>
        <Card cardTitle={'Exclusive'}>
          <Text>Mint Exclusive NFT</Text>
          <ThirdImgWrapper>
            <ThirdCardImg />
          </ThirdImgWrapper>
        </Card>
        <Card cardTitle={'10% revenue'}>
          <Text>Get 10% revenue from all WOW tickets purchases</Text>
          <FourthImgWrapper>
            <FourthCardImg />
          </FourthImgWrapper>
        </Card>
        <Card cardTitle={'Promote'}>
          <Text>Promote WOW Events and grow your earnings</Text>
          <FifthImgWrapper>
            <FifthCardImg />
          </FifthImgWrapper>
        </Card>
      </CardsWrapper>
    </Wrapper>
  );
};

export default HowItWork;