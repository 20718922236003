import React from 'react';
import NextGenerationText from "../../icons/NextGenertationText/NextGenerationText.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #000000;
  height: 130px;
  overflow: hidden;

  img {
    display: inline-block;
    width: 11312px;
    text-indent: 0;
    animation: marquee 70s linear infinite
  }

  @keyframes marquee {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(100%, 0);
    }
  }

  @media only screen and (max-width: 440px) {
    .marquee img {
      animation: none;
      padding-left: 0
    }
  }
`
const NextGenerationBlackBlock = () => {
  return (
    <Wrapper>
      <img src={NextGenerationText} alt="NEXT GENERATION TEXT"/>
    </Wrapper>
  );
};

export default NextGenerationBlackBlock;