import FifthLogo from './image 52.png'
import SixthLogo from './image 53.png'
import SeventhLogo from './image 54.png'
import EighthLogo from './image 55.png'
import NinthLogo from './image 56.png'
import TenthLogo from './image 57.png'
import ThirteenthLogo from './image 60.png'
import FourteenthLogo from './image 61.png'
import FifteenthLogo from './image 62.png'
import SixteenthLogo from './image 63.png'
import SeventeenthLogo from './image 64.png'
import ThirtiethLogo from './image 68.png'
import FourtiethLogo from './image 69.png'
import FiftiethLogo from './image 70.png'
import SixtiethLogo from './image 71.png'
import SeventiethLogo from './image 72.png'
import TwentyFirstLogo from './image 75.png'
import TwentySecondLogo from './image 76.png'
import TwentyThirdLogo from './image 77.png'
import TwentyFourthLogo from './image 78.png'
import TwentyFifthLogo from './image 79.png'
import TwentySixthLogo from './image 80.png'
import TwentySeventhLogo from './image 81.png'
import TwentyEighthLogo from './image 82.png'
import TwentyNinthLogo from './image 83.png'
import TwentyTenthLogo from './image 84.png'
import TwentyEleventhLogo from './image 85.png'
import TwentyThirteenthLogo from './image 86.png'
import TwentyFourtiethLogo from './image 87.png'
import TwentyFiftiethLogo from './image 88.png'
import TwentySixtiethLogo from './image 89.png'
import TwentySeventiethLogo from './image 90.png'
import TwentyEightiethLogo from './image 91.png'
import TwentyNinetiethLogo from './image 92.png'
import TwentyTwentiethLogo from './image 93.png'
import TwentyThirtyFirstLogo from './image 94.png'
import TwentyTwentyFirstLogo from './image 95.png'
import TwentyTwentySecondLogo from './image 96.png'
import TwentyTwentyThirdLogo from './image 97.png'
import TwentyTwentyFourthLogo from './image 98.png'
import TwentyTwentyFifthLogo from './image 99.png'
import TwentyTwentySixthLogo from './image 100.png'
import TwentyTwentySeventhLogo from './image 101.png'
import TwentyTwentyEighthLogo from './image 102.png'
import TwentyTwentyNinthLogo from './image 103.png'
import TwentyTwentyThirteenthLogo from './image 106.png'

export const photoArray = [
  FifthLogo,
  SeventhLogo,
  EighthLogo,
  NinthLogo,
  TenthLogo,
  ThirteenthLogo,
  FourteenthLogo,
  FifteenthLogo,
  SixteenthLogo,
  SeventeenthLogo,
  ThirtiethLogo,
  FourtiethLogo,
  FiftiethLogo,
  SixtiethLogo,
  SeventiethLogo,
  TwentyFirstLogo,
  TwentySecondLogo,
  TwentyThirdLogo,
  TwentyFourthLogo,
  TwentyFifthLogo,
  TwentySixthLogo,
  TwentySeventhLogo,
  TwentyEighthLogo,
  TwentyNinthLogo,
  TwentyTenthLogo,
  TwentyEleventhLogo,
  TwentyThirteenthLogo,
  TwentyFourtiethLogo,
  TwentyFiftiethLogo,
  TwentySixtiethLogo,
  TwentySeventiethLogo,
  TwentyEightiethLogo,
  TwentyNinetiethLogo,
  TwentyTwentiethLogo,
  TwentyThirtyFirstLogo,
  TwentyTwentyFirstLogo,
  TwentyTwentySecondLogo,
  TwentyTwentyThirdLogo,
  TwentyTwentyFourthLogo,
  TwentyTwentyFifthLogo,
  TwentyTwentySixthLogo,
  TwentyTwentySeventhLogo,
  TwentyTwentyEighthLogo,
  TwentyTwentyNinthLogo,
  TwentyTwentyThirteenthLogo,
]