import React from 'react';
import styled from "styled-components";
import {StyledVars} from "../../../globalStyles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  gap: 22px;
`

const WOWNftText = styled.span`
  font-weight: 600;
  font-size: 92px;
  color: #C75FEA;
  z-index: 2;
  white-space: nowrap;
	transition: all .3s;

  @media screen and (max-width: 1400px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
  }
`

const PrivateSaleText = styled.div`
  font-weight: 600;
  font-size: 92px;
	color: #181833;
	z-index: 2;
	white-space: nowrap;
	transition: all .3s;

  @media screen and (max-width: 1400px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
	
	@media screen and ${StyledVars.media.big} {
		flex-direction: column;
		align-items: start;
	}
	
	@media screen and ${StyledVars.media.some}{
		flex-direction: row;
		align-items: start;
	}
	
	@media screen and (max-width: 800px){
		flex-direction: column;
	}
`

const Subtitle = styled.div`
	transition: all .3s;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #181833;
	
  @media screen and (max-width: 600px) {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
  }
`

const PointsWrapper = styled.div`
  display: flex;
	flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  z-index: 2;

	@media screen and (max-width: 600px){
		flex-direction: column;
		align-items: start;
		gap: 8px !important;
	}
	
  @media screen and ${StyledVars.media.big} {
    flex-direction: column;
    align-items: start;
  }

	@media screen and ${StyledVars.media.some}{
		flex-direction: row;
		align-items: start;
	}
`

const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`

const PointText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #181833;

	@media screen and (max-width: 600px) {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 150%;
	}
`

const Button = styled.button`
  height: 90px;
  padding: 22px 36px;
  text-transform: uppercase;
  background: #FFFFFF;
  border: 2px solid #040611;
  color: #040611;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  cursor: pointer;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  a {
    text-decoration: none !important;
  }
`

const Description = () => {
	return (
		<Wrapper>
			<TitleWrapper>
				<WOWNftText>WOW NFT</WOWNftText>
				<PrivateSaleText>Private Sale</PrivateSaleText>
			</TitleWrapper>
			{/*<Subtitle>Starts on the 12th of April</Subtitle>*/}
			<PointsWrapper>
				<PointWrapper>
					<PointText>
						Current price:
						<strong> USDT 15.000</strong>
					</PointText>
				</PointWrapper>
				{/*<PointWrapper>*/}
				{/*	<PointText>*/}
				{/*		Selling opportunities will be open every <strong>5 minutes for any 5 NFTs</strong>. 30 NFTs will be sold*/}
				{/*	</PointText>*/}
				{/*</PointWrapper>*/}
				<PointWrapper>
					<PointText>
						The difference between Private and Public rounds is <strong style={{color: '#C75FEA'}}>66%</strong> of the NFT price. Do not miss a chance to be the first
					</PointText>
				</PointWrapper>
			</PointsWrapper>
			{/*<ButtonsWrapper>*/}
			{/*	<Button*/}
			{/*		onClick={() => window.open('https://drive.google.com/file/d/1dovsWZ8aD6h0iTcwWFQA-GtqXB_KMDX6/view?usp=drivesdk', '_blank')}>*/}
			{/*		White Paper*/}
			{/*	</Button>*/}
			{/*	/!*<Button*!/*/}
			{/*	/!*	onClick={() => window.open('https://wowsummitnft.com/', '_blank')}*!/*/}
			{/*	/!*>*!/*/}
			{/*	/!*	WOW NFT Site*!/*/}
			{/*	/!*</Button>*!/*/}
			{/*</ButtonsWrapper>*/}
		</Wrapper>
	);
};

export default Description;
