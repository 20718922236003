export default () => {
  return (
    <svg width="220" height="70" viewBox="0 0 220 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 20H90V50H0V20Z" fill="#C75FEA"/>
      <path opacity="0.1" d="M130 20H220V50H130V20Z" fill="#C75FEA"/>
      <path
        d="M83.75 8.75H136.25C137.024 8.75 139.167 8.75 139.167 8.75C139.167 8.75 139.167 10.8931 139.167 11.6667V58.3333C139.167 59.1069 139.167 61.25 139.167 61.25C139.167 61.25 137.024 61.25 136.25 61.25H83.75C82.9764 61.25 80.8333 61.25 80.8333 61.25C80.8333 61.0862 80.8333 59.1069 80.8333 58.3333V11.6667C80.8333 10.8931 80.8333 8.75 80.8333 8.75C80.8333 8.75 82.9764 8.75 83.75 8.75ZM99.7916 40.8333V46.6667H107.083V52.5H112.917V46.6667H115.833C117.767 46.6667 119.622 45.8984 120.989 44.531C122.357 43.1635 123.125 41.3089 123.125 39.375C123.125 37.4411 122.357 35.5865 120.989 34.219C119.622 32.8516 117.767 32.0833 115.833 32.0833H104.167C103.78 32.0833 103.409 31.9297 103.135 31.6562C102.862 31.3827 102.708 31.0118 102.708 30.625C102.708 30.2382 102.862 29.8673 103.135 29.5938C103.409 29.3203 103.78 29.1667 104.167 29.1667H120.208V23.3333H112.917V17.5H107.083V23.3333H104.167C102.233 23.3333 100.378 24.1016 99.0107 25.469C97.6432 26.8365 96.875 28.6911 96.875 30.625C96.875 32.5589 97.6432 34.4135 99.0107 35.781C100.378 37.1484 102.233 37.9167 104.167 37.9167H115.833C116.22 37.9167 116.591 38.0703 116.865 38.3438C117.138 38.6173 117.292 38.9882 117.292 39.375C117.292 39.7618 117.138 40.1327 116.865 40.4062C116.591 40.6797 116.22 40.8333 115.833 40.8333H99.7916Z"
        fill="#C75FEA"/>
    </svg>
  )
}