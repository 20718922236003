import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {injected} from "../../web3/connectors";
import {useWeb3React} from "@web3-react/core";
import Description from "./Description";
import Video from "./Video";
import MintButtons from "./MintButtons";
import Timer from "./Timer";
import {NotificationContext, PopupContext} from "../../context";
import {usePrivateSaleContract} from "../../hooks/useContracts";
import Notification from "../../ui/Notification";
import useIsFirstRender from "../../hooks/useFirstRender";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 20px;
	overflow-x: hidden;
	margin-bottom: 20px;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0 13px;
  }
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MainPage = () => {

	// @ts-ignore
	const {active, activate, networkError} = useWeb3React();
	const privateSaleContract = usePrivateSaleContract()
	const {shouldDisplayNotification, notificationType, notificationSubtitle, notificationTitle} = useContext(NotificationContext)

	const [pauseTime, setPauseTime] = useState(0);
	const [isMintInMMPRO, setIsMintInMMPRO] = useState(false)
	const [lastSaleTime, setLastSaleTime] = useState(Date.now() / 1000)

	useEffect(() => {
		injected.isAuthorized().then((isAuthorized) => {
			if (isAuthorized && !active && !networkError) {
				activate(injected);
			}
		});
	}, [activate, networkError]);

	useEffect(() => {
		updateValuesFromContract()
	}, []);

	const updateValuesFromContract = () => {
		privateSaleContract.methods.isMMPRO().call().then((newIsMintInMMPRO: boolean) => {
			setIsMintInMMPRO(newIsMintInMMPRO)
		})
		privateSaleContract.methods.timestamp().call().then((newLastSaleTime: number) => {
			setLastSaleTime(newLastSaleTime)
		})
	}

	const isPause = (Date.now() / 1000 - lastSaleTime) <= pauseTime

	return (
		<Container>
			<Notification
				type={notificationType}
				shouldDisplay={shouldDisplayNotification}
				title={notificationTitle}
				subtitle={notificationSubtitle}
			/>
			<ContentWrapper>
				<Description/>
				<RightBlock>
					<Video/>
					{/*<Timer*/}
					{/*	isPause={isPause}*/}
					{/*	callback={updateValuesFromContract}*/}
					{/*	toTime={isPause ? (+lastSaleTime) : Date.now() / 1000}*/}
					{/*/>*/}
					<MintButtons
						onClick={updateValuesFromContract}
						usdtDisabled={isMintInMMPRO || isPause}
						mmproDisabled={!isMintInMMPRO || isPause}
					/>
				</RightBlock>
			</ContentWrapper>
		</Container>
	);
};

export default MainPage;
