import React from 'react';
import styled from "styled-components";
import SummitPhoto from '../../icons/WhyWillItWorkPage/SummitPhoto.png'
import BgText from '../../icons/WhyWillItWorkPage/circulation'
import Tier from '../../icons/WhyWillItWorkPage/tier'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1920px;
  padding: 70px 120px;
  position: relative;
  overflow: hidden;
  gap: 25px;

  @media screen and (max-width: 1000px) {
    padding: 70px 20px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
`

const Title = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 128px;
  line-height: 115px;
  text-transform: uppercase;
  color: #040611;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
  }
`

const SubTitle = styled.span`
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  text-transform: uppercase;
  color: #040611;
  max-width: 900px;

  @media screen and (max-width: 1000px) {
    font-size: 36px;
    line-height: 45px;
    max-width: 600px;
  }
  
  @media screen and (max-width: 600px) {
    width: 280px;
  }
`

const PhotoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  position: relative;
`

const PhotoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 1;
  
  @media screen and (max-width: 600px) {
    img {
      margin-left: 400px;
      height: 350px;
      width: 450px;
    }
  }
`

const PhotoText = styled.span`
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-align: right;
  text-transform: uppercase;
  color: #040611;
  max-width: 660px;
  
  @media screen and (max-width: 1000px) {
    font-size: 28px;
    line-height: 35px;
  }
`

const StyleBgText = styled.div`
  position: absolute;
  z-index: 0;
  right: -150px;
  top: 125px;

  @media screen and (max-width: 600px) {
    top: 55px;
  }
`

const TierBLock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`

const TierText = styled.span`
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-transform: uppercase;
  color: #040611;
  width: 660px;
  
  @media screen and (max-width: 1000px) {
    font-size: 28px;
    line-height: 35px;
  }
  
  @media screen and (max-width: 600px) {
    width: 280px;
  }
`

const TierPhotoWrapper = styled.div`
  @media screen and (max-width: 1000px) {
    svg {
      width: 235px;
      height: 60px;
    }
  }
`

const WhyWillItWork = () => {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>why will it work?</Title>
        <SubTitle>WOW ecosystem is a bridge between the physical and digital Worlds</SubTitle>
      </TitleWrapper>
      <PhotoBlock>
        <PhotoWrapper>
          <img src={SummitPhoto} alt="Summit Photo"/>
        </PhotoWrapper>
        <PhotoText>The token circulation is linked to the actual performance of WOW events</PhotoText>
        <StyleBgText>
          <BgText/>
        </StyleBgText>
      </PhotoBlock>
      <TierBLock>
        <TierPhotoWrapper>
          <Tier />
        </TierPhotoWrapper>
        <TierText>
          WOW events became Tier-1 web3 events around the World and we are growing rapidly
        </TierText>
      </TierBLock>
    </Wrapper>
  );
};

export default WhyWillItWork;