import React from 'react';
import styled from "styled-components";
import Crown from "../../icons/WhatIsWowTokenPage/crown";
import Dialog from "../../icons/WhatIsWowTokenPage/diamond"
import Utility from "../../icons/WhatIsWowTokenPage/utility";
import WebSocket from "../../icons/WhatIsWowTokenPage/web3"

const Wrapper = styled.div`
  max-width: 1920px;
  padding: 70px 120px;

  @media screen and (max-width: 1400px) {
   padding: 70px 20px;
  }
`

const Title = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 92px;
  line-height: 115px;
  text-transform: uppercase;
  color: #040611;
  margin-bottom: 45px;
  
  @media screen and (max-width: 1400px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
  }
`

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  
  @media screen and (max-width: 900px) {
    align-items: center;
    justify-content: center;
  }
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 260px;
  gap: 20px;
  
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const CardText = styled.span`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #040611;
`

const Button = styled.button`
  height: 90px;
  padding: 22px 36px;
  text-transform: uppercase;
  background: #FFFFFF;
  border: 2px solid #040611;
  color: #040611;
  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  cursor: pointer;
`
const WhatIsWowToken = () => {
  return (
    <Wrapper>
      <Title>what is wow token?</Title>
      <CardsWrapper>
        <CardWrapper>
          <Crown />
          <CardText>Exclusive WOW NFT purchases</CardText>
          <a href="https://forms.gle/zVsiT6dp815vT37RA" target={'_blank'}>
            <Button>buy nft</Button>
          </a>
        </CardWrapper>
        <CardWrapper>
          <Dialog />
          <CardText>Get special discounts on tickets and exhibition packages</CardText>
        </CardWrapper>
        <CardWrapper>
          <Utility />
          <CardText>Utility token for WOW ecosystem with loyalty functions</CardText>
        </CardWrapper>
        <CardWrapper>
          <WebSocket />
          <CardText>Web3 Staking</CardText>
        </CardWrapper>
      </CardsWrapper>
    </Wrapper>
  );
};

export default WhatIsWowToken;