import React from 'react';
import styled from "styled-components";
import WowNft from '../../icons/WowNFT/nft.png';
import BgText from '../../icons/IntroductionBgText';
import MMPROLogo from "../../icons/MMProLogo";
import UveconLogo from "../../icons/UveconLogo/logo.svg";
import GuyWayLogo from "../../icons/GuyWayLogo/logo.png";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #040611;
  max-width: 1920px;
  padding: 0 120px;
  height: 690px;
  
  @media screen and (max-width: 1400px) {
    height: max-content;
    padding: 30px 20px;
    justify-content: center;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  max-width: 700px;
  
  @media screen and (max-width: 1400px) {
    max-width: 800px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 1400px) {
   
  }
`

const IntroducingText = styled.span`
  font-weight: 600;
  font-size: 54px;
  line-height: 60px;
  color: #fff;
  text-transform: uppercase;
  
  @media screen and (max-width: 1400px) {
    font-size: 40px;
    line-height: 44px;
  }

  @media screen and (max-width: 560px) {
    font-weight: 600;
    font-size: 40px;
  }
`

const WowNftAndWowToken = styled.span`
  font-weight: 700;
  font-size: 130px;
  line-height: 143px;
  color: #fff;
  text-transform: uppercase;
  
  @media screen and (max-width: 1400px) {
    font-size: 66px;
    line-height: 80px;
  }

  @media screen and (max-width: 560px) {
    font-weight: 700;
    font-size: 76px;
  }
`

const StyledNftImage = styled.img`
  position: absolute;
  width: 360px;
  height: 590px;
  top: 50px;
  right: 110px;
  z-index: 1;
  
  @media screen and (max-width: 1400px) {
    display: none;
  }
`

const StyledMobileNftImage = styled.img`
  display: none;
  z-index: 1;
  
  @media screen and (max-width: 1400px) {
    display: block;
  }

  @media screen and (max-width: 560px) {
    width: 240px;
    height: 400px;
  }
`

const StyledBgText = styled.div`
  position: absolute;
  height: 730px;
  bottom: 0;
  right: 0;
  z-index: 0;
  
  @media screen and (max-width: 1400px) {
    width: 100%;
    top: 0;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    top: -150px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  
  @media screen and (max-width: 1400px) {
    align-items: center;
  }

  @media screen and (max-width: 560px) {
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
  }
`

const Button = styled.button`
  height: 80px;
  background: #FFFFFF;
  border: 2px solid #000000;
  font-weight: 500;
  font-size: 32px;
  color: #040611;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 11px 13px;
  cursor: pointer;
`

const BorderedButton = styled(Button)`
  background: #040611;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
`

const ButtonWrapperText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #FFFFFF;
  
  @media screen and (max-width: 700px) {
    font-size: 20px;
    line-height: 32px;
  }
  
  @media screen and (max-width: 560px) {
    display: none;
  }
`

const SupportByWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  
  @media screen and (max-width: 1400px) {
    width: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: 700px) {
    gap: 8px
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const SupportByText = styled.span`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #FFFFFF;

  @media screen and (max-width: 700px) {
    font-size: 16px;
    line-height: 32px;
  }

  @media screen and (max-width: 500px) {
    font-weight: 400;
    font-size: 32px;
  }
`

const Introduction = () => {
  return (
    <Wrapper>
      <Content>
        <StyledMobileNftImage src={WowNft} alt="Wow NFT" />
        <TextWrapper>
          <IntroducingText>introducing</IntroducingText>
          <WowNftAndWowToken>Wow NFT & Wow Token</WowNftAndWowToken>
        </TextWrapper>
        <ButtonWrapper>
          <a href="https://forms.gle/zVsiT6dp815vT37RA" target={'_blank'}>
            <Button>whitelist</Button>
          </a>
          <a href="https://drive.google.com/file/d/1dovsWZ8aD6h0iTcwWFQA-GtqXB_KMDX6/view" target={'_blank'}>
            <BorderedButton>
              WP
            </BorderedButton>
          </a>
          <a href="/assets/Tokenomics.pdf">
            <BorderedButton>
              TOKENOMICS
            </BorderedButton>
          </a>
          <ButtonWrapperText>Making the Future of Web3 Accessible for Anyone</ButtonWrapperText>
        </ButtonWrapper>
        <SupportByWrapper>
          <SupportByText>supported by</SupportByText>
          <a href="https://marketmaking.pro/" target='_blank'>
            <MMPROLogo />
          </a>
          <a href="#">
            <img src={UveconLogo} alt=""/>
          </a>
          {/*<a href="#">*/}
          {/*  <img src={GuyWayLogo} alt="GuyWay"/>*/}
          {/*</a>*/}
        </SupportByWrapper>
      </Content>
      <StyledNftImage src={WowNft} alt="NFT"/>
      <StyledBgText>
        <BgText />
      </StyledBgText>
    </Wrapper>
  );
};

export default Introduction;