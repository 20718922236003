import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import Mmpro from "../../../icons/mmpro";
import Usdt from "../../../icons/usdt";
import {useWeb3React} from "@web3-react/core";
import {NotificationContext, PopupContext} from "../../../context";
import {useMMProContract, usePrivateSaleContract, useUSDTContract} from "../../../hooks/useContracts";
import fromExponential from "from-exponential";
import {getPrivateSaleAddress} from "../../../web3/address";
import Spinner from "../../../ui/Spinner";

const ALLOWANCE = 10 ** 10 * 10 ** 18

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const EndSaleWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EndSaleText = styled.span`
  font-size: 22px;
  font-weight: 600;
  color: #181833;
`

const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 80px;
  z-index: 2;

  @media screen and (max-width: 600px) {
    width: min-content;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 430px) {
    gap: 40px;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 190px;
  height: 60px;
  background: ${({disabled}) => disabled ? 'white' : 'white'};
  border: 1px solid ${({disabled}) => disabled ? '#7D7D91' : 'rgb(199, 95, 234)'};
  border-radius: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${({disabled}) => disabled ? '#7D7D91' : 'rgb(199, 95, 234)'};
  //border: none;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 159px;
    height: 54px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
  }

  @media screen and (max-width: 450px) {
    font-size: 18px;
    line-height: 125%;
  }
`

const IconWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: ${({disabled}) => disabled ? '#7D7D91' : 'black'};
  border-radius: 50%;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PriceText = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 49px;
  text-transform: uppercase;
  color: #181833;

  @media screen and (max-width: 600px) {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 430px) {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
  }
`

const CurrencyText = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 49px;
  text-transform: uppercase;
  color: #181833;

  @media screen and (max-width: 600px) {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23.54px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 430px) {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
  }
`

const PriceTextsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
`

const ButtonOverlay = styled.button<{ notConnected: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: ${({notConnected}) => notConnected ? 'none' : 'normal'};
  background: rgba(256, 256, 256, 0.9);
  border: 1px solid #C75FEA;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #C75FEA;
  cursor: pointer;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`

type BuyButtonProps = {
	mmproDisabled: boolean
	usdtDisabled: boolean
	onClick: () => void
}

const BuyButtons = ({mmproDisabled, usdtDisabled, onClick}: BuyButtonProps) => {

	const {account} = useWeb3React()
	const {setOpen} = useContext(PopupContext)
	const {displayNotification} = useContext(NotificationContext)

	const [usdtAllowance, setUsdtAllowance] = useState<string | undefined>(undefined)
	const [mmproAllownce, setMmproAllownce] = useState<string | undefined>(undefined)
	const [isUsdtApproveLoading, setIsUsdtApproveLoading] = useState<boolean>(false)
	const [isMmproApproveLoading, setIsMmproApproveLoading] = useState<boolean>(false)
	const [isMmproBuyLoading, setIsMmproBuyLoading] = useState<boolean>(false)
	const [isUsdtBuyLoading, setIsUsdtBuyLoading] = useState<boolean>(false)
	const [mmproPrice, setMmproPrice] = useState<number | undefined>(undefined)
	const [usdtPrice, setUsdtPrice] = useState<number | undefined>(undefined)
	const [totalSupply, setTotalSupply] = useState<number | undefined>(undefined)
	const [limit, setLimit] = useState<number | undefined>(undefined)

	const usdtContract = useUSDTContract()
	const privateSaleContract = usePrivateSaleContract()
	const mmproContract = useMMProContract()
	const getMmproPrice = async () => {
		const price = await privateSaleContract.methods.MMPROprice().call()
		setMmproPrice(+price)
	}
	const getUsdtPrice = async () => {
		const price = await privateSaleContract.methods.USDTprice().call()
		setUsdtPrice(+price)
	}
	const getAllowance = async (contract: any): Promise<string> => {
		return await contract
			.methods
			.allowance(account, getPrivateSaleAddress())
			.call()
	}
	const approve = async (
		contract: any,
		isApproveLoading: boolean,
		setIsApproveLoading: (newValue: boolean) => void,
		tokenType: 'usdt' | 'mmpro'
	) => {

		if (isApproveLoading) {
			return
		}

		setIsApproveLoading(true)
		const amount2eth = fromExponential(ALLOWANCE);
		try {
			await contract
				.methods
				.approve(getPrivateSaleAddress(), amount2eth)
				.send({from: account}).once('receipt', async () => {
					const {newUsdtAllowance, newMmproAllowance} = await updateAllowances()

					if (tokenType === 'usdt' && usdtPrice) {
						if (parseInt(newUsdtAllowance) >= usdtPrice) {
							displayNotification(
								'default',
								'Success',
								'USDT approve was successful!'
							)
						} else {
							displayNotification(
								'warning',
								'Warning',
								`Not enough allowance - ${(usdtPrice / 10**18 - +newUsdtAllowance / 10**18).toFixed(1)} USDT`
							)
						}
					}

					if (tokenType === 'mmpro' && mmproPrice) {
						if (parseInt(newMmproAllowance) >= mmproPrice) {
							displayNotification(
								'default',
								'Success',
								'MMPRO approve was successful!'
							)
						} else {
							displayNotification(
								'warning',
								'Warning',
								`Not enough allowance - ${(mmproPrice / 10**18 - +newMmproAllowance / 10**18).toFixed(1)} MMPRO`
							)
						}
					}


					setIsApproveLoading(false)
				});
		} catch (e: any) {
			console.log(e)
			if (e.message.includes('insufficient funds')) {
				displayNotification(
					'error',
					'Failure',
					'Not enough funds!'
				)
			} else {
				displayNotification(
					'error',
					'Failure',
					'Please try again!'
				)
			}

			setIsApproveLoading(false)
		}
	};
	const updateAllowances = async (): Promise<any> => {
		const newUsdtAllowance = await getAllowance(usdtContract)
		const newMmproAllowance = await getAllowance(mmproContract)

		setUsdtAllowance(newUsdtAllowance)
		setMmproAllownce(newMmproAllowance)

		return {
			newUsdtAllowance,
			newMmproAllowance
		}
	}
	const mintByMmpro = async () => {
		onClick()
		setIsMmproBuyLoading(true)
		try {
			await privateSaleContract.methods.mintWithMMPro()
				.send({from: account})
				.once('receipt', () => {
					getTotalSupply()
					setIsMmproBuyLoading(false)
					displayNotification(
						'default',
						'Success',
						'Mint was successful!'
					)
					onClick()
				})
		} catch (e) {
			console.log(e)
			displayNotification(
				'error',
				'Failure',
				'Please try again!'
			)
			onClick()
			setIsMmproBuyLoading(false)
		}
	}
	const mintByUsdt = async () => {
		onClick()
		if (!usdtPrice) {
			return
		}
		setIsUsdtBuyLoading(true)
		try {
			await privateSaleContract.methods.mintWithUSDT()
				.send({from: account})
				.once('receipt', () => {
					getTotalSupply()
					displayNotification(
						'default',
						'Success',
						'Mint was successful!'
					)
					onClick()
					setIsUsdtBuyLoading(false)
				})
		} catch (e) {
			console.log(e)
			displayNotification(
				'error',
				'Failure',
				'Please try again!'
			)
			onClick()
			setIsUsdtBuyLoading(false)
		}
	}
	const getTotalSupply = async () => {
		const totalSupply = await privateSaleContract.methods.totalSupply().call()
		setTotalSupply(+totalSupply)
	}
	const getLimit = async () => {
		const limit = await privateSaleContract.methods.limitSupply().call()
		setLimit(+limit)
	}

	const isUsdtApprovalRequired = usdtAllowance && usdtPrice && usdtPrice > parseInt(usdtAllowance) ;
	const isMMproApprovalRequired = mmproAllownce && mmproPrice && mmproPrice > parseInt(mmproAllownce);

	useEffect(() => {
		getLimit()
		getUsdtPrice()
		getMmproPrice()
		getTotalSupply()
	}, [])

	useEffect(() => {
		if (account) {
			updateAllowances()
		}
	}, [account])

	return (
		<ContentWrapper>
			{limit !== undefined && totalSupply !== undefined && limit !== totalSupply &&
          <TextWrapper>
              <PriceTextsWrapper>
                <PriceText>{mmproPrice ? (+mmproPrice / 10 ** 18).toFixed(0) : ''}</PriceText>
                <CurrencyText>mmpro</CurrencyText>
              </PriceTextsWrapper>
              <PriceTextsWrapper>
                  <PriceText>{usdtPrice ? (+usdtPrice / 10 ** 18).toFixed(0) : ''}</PriceText>
                  <CurrencyText>usdt</CurrencyText>
              </PriceTextsWrapper>
          </TextWrapper>
			}
			{limit !== undefined && totalSupply !== undefined && limit === totalSupply ?
				<EndSaleWrapper>
					<EndSaleText>
						Private Sale closed. Thank you!
					</EndSaleText>
				</EndSaleWrapper>
				:
				<ButtonsWrapper>
					{/*<Button*/}
					{/*	disabled={(mmproDisabled || !account) && !isMMproApprovalRequired}*/}
					{/*	onClick={*/}
					{/*		isMMproApprovalRequired ?*/}
					{/*			() => approve(mmproContract, isMmproApproveLoading, setIsMmproApproveLoading, 'mmpro')*/}
					{/*			:*/}
					{/*			mintByMmpro*/}
					{/*	}*/}
					{/*>*/}
					{/*	<IconWrapper disabled={(mmproDisabled || !account) && !isMMproApprovalRequired}>*/}
					{/*		<Mmpro/>*/}
					{/*	</IconWrapper>*/}
					{/*	{*/}
					{/*		isMmproApproveLoading || isMmproBuyLoading ?*/}
					{/*			<SpinnerWrapper>*/}
					{/*				<Spinner color={'rgb(199, 95, 234)'} size={25}/>*/}
					{/*			</SpinnerWrapper>*/}
					{/*			:*/}
					{/*			<>*/}
					{/*				{isMMproApprovalRequired ? 'approve' : 'buy nft'}*/}
					{/*			</>*/}
					{/*	}*/}
					{/*</Button>*/}
					<Button
						onClick={
							isUsdtApprovalRequired ?
								() => approve(usdtContract, isUsdtApproveLoading, setIsUsdtApproveLoading, 'usdt')
								:
								mintByUsdt
						}
					>
						<IconWrapper disabled={(usdtDisabled || !account) && !isUsdtApprovalRequired}>
							<Usdt/>
						</IconWrapper>
						{
							isUsdtApproveLoading || isUsdtBuyLoading ?
								<SpinnerWrapper>
									<Spinner color={'rgb(199, 95, 234)'} size={25}/>
								</SpinnerWrapper>
								:
								<>
									{isUsdtApprovalRequired ? 'approve' : 'buy nft'}
								</>
						}
					</Button>
					<ButtonOverlay notConnected={!!account} onClick={() => {
						window.scrollTo(0, 0)
						setOpen(true)
					}} style={{cursor: 'pointer'}}>
						Connect Wallet
					</ButtonOverlay>
				</ButtonsWrapper>
			}
		</ContentWrapper>
	);
};

export default BuyButtons;
