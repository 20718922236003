import React from 'react';
import styled from "styled-components";
import WhatIsWowNftShape from "../../icons/WhatIsWowNftShape";
import WhatIsWowNftShapeMobile from "../../icons/WhatIsWowNftShapeMobile";

const Wrapper = styled.div`
  max-width: 1920px;
  padding: 70px 120px;
  position: relative;
  padding-bottom: 0;
  height: 1000px;
  overflow: hidden;
  
  @media screen and (max-width: 1400px) {
    padding: 70px 20px;
  }
`

const Title = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 92px;
  line-height: 115px;
  text-transform: uppercase;
  color: #040611;
  position: relative;
  z-index: 1;
  
  @media screen and (max-width: 1400px) {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
  }
`

const StyledImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 950px;

  @media screen and (max-width: 1400px) {
    height: 1070px;
  }

  @media screen and (max-width: 1250px) {
    height: 1250px;
  }
  
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const StyledImageMobileWrapper = styled.div`
  display: none;
  position: absolute;
  z-index: 0;
  @media screen and (max-width: 1000px) {
    display: block;
    top: -270px;
    right: 0;
  }
`

const TextWrapper = styled.div`
  display: none;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 60px;
  position: absolute;
  z-index: 1;
  
  @media screen and (max-width: 1000px) {
    display: flex;
    height: max-content;
  }
`

const Text = styled.span`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #040611;
  width: 270px;
`

const WhatIsWowNft = () => {
  return (
    <Wrapper>
      <Title>What is WOW NFT?</Title>
      <TextWrapper>
        <Text>Get 10% income from all tickets purchases</Text>
        <Text>VIP Invitation to WOW Events</Text>
        <Text>Special reward program for tickets and booths purchases</Text>
        <Text>Unlimited access to closed allocations and insights</Text>
        <Text>Personal manager and business concierge service during WOW Summit</Text>
      </TextWrapper>
      <StyledImageWrapper>
        <WhatIsWowNftShape />
      </StyledImageWrapper>
      <StyledImageMobileWrapper>
        <WhatIsWowNftShapeMobile />
      </StyledImageMobileWrapper>
    </Wrapper>
  );
};

export default WhatIsWowNft;