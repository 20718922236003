import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {photoArray} from "../../icons/PartnersAndSponsorsPage";
import ButtonArrow from "../../icons/ButtonArrow";

const TitleWrapper = styled.div`
  padding: 50px 120px;
  background: #040611;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  @media screen and (max-width: 1400px) {
    padding: 45px 20px;
    font-size: 54px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 1400px) {
    align-items: center;
    justify-content: center;
  }
  
  .partnerImg {
    width: 200px;
    height: 144px;
  }
`

const StyledButton = styled.button`
  width: 166px;
  height: 54px;
  border: 2px solid #040611;
  padding: 11px 17px;
  display: flex;
  justify-content: ${p => p.isActive ? 'center' : 'space-between'};
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  background: #fff;
  cursor: pointer;
  color: #040611;
`

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  padding: 70px 120px;

  @media screen and (max-width: 1400px) {
    padding: 70px 20px;
    align-items: center;
    justify-content: center;
  }
`

const ArrowWrapper = styled.div`
  svg {
    transform: ${p => p.isActive? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: all 0.3s;
  }
`

const PartnersAndSponsors = () => {
  const [isShowAllPartners, setIsShowAllPartners] = useState(false);

  const toggleButton = () => {
    setIsShowAllPartners(!isShowAllPartners);
  }

  return (
    <div>
      <TitleWrapper>Partners and Sponsors Of WOWSUMMIT</TitleWrapper>
      <BlockWrapper>
        <ContentWrapper>
          {(isShowAllPartners ? photoArray : photoArray.slice(0, 15)).map((photo, index) => (
            <div key={index}>
              <img src={photo} alt="partner" className='partnerImg'/>
            </div>
          ))}
        </ContentWrapper>
        <StyledButton onClick={toggleButton} isActive={isShowAllPartners}>
          {isShowAllPartners? '' : 'More'}
          <ArrowWrapper isActive={isShowAllPartners}>
            <ButtonArrow />
          </ArrowWrapper>
        </StyledButton>
      </BlockWrapper>
    </div>
  );
};

export default PartnersAndSponsors;