import React from 'react';
import styled from "styled-components";
import BgText from "../../icons/WhyWillItWorkPage/text.svg";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #C75FEA;
  height: 70px;
  overflow: hidden;

  img {
    display: inline-block;
    width: 3310px;
    text-indent: 0;
    animation: marquee 60s linear infinite
  }

  @keyframes marquee {
    0% {
      transform: translate(100%, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  @media only screen and (max-width: 440px) {
    .marquee img {
      animation: none;
      padding-left: 0
    }
  }
`
const WhyWillItWorkPurpleBlock = () => {
  return (
    <Wrapper>
      <img src={BgText} alt=""/>
    </Wrapper>
  );
};

export default WhyWillItWorkPurpleBlock;