import React from 'react';
import styled from "styled-components";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px
`

const CardContent = styled.div`
  position: relative;
  width: 200px;
  height: 260px;
  background: #fff;
  z-index: 4;
  padding: 10px;
  overflow: hidden;
`

const CardTitle = styled.div`
  width: 200px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-transform: uppercase;
  color: #C75FEA;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  background: #fff;
`

const Card = (props) => {
  const {children, cardTitle} = props;
  return (
    <CardWrapper>
      <CardTitle>{cardTitle}</CardTitle>
      <CardContent>
        {children}
      </CardContent>
    </CardWrapper>
  );
};

export default Card;