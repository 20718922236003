export default () => {
  return (
    <svg width="220" height="62" viewBox="0 0 220 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_530_273)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M-6 62.3538L48 31.1769L-6 0L-6 16.0001H-30V46.0001H-6L-6 62.3538Z" fill="#C75FEA"/>
      </g>
      <path opacity="0.1" d="M182 15H220V45H182V15Z" fill="#C75FEA"/>
      <path
        d="M101.06 23V34.36H106.4V37H98.3V23H101.06ZM118.889 35.18C117.475 36.58 115.755 37.28 113.729 37.28C111.702 37.28 109.982 36.58 108.569 35.18C107.169 33.7533 106.469 32.0267 106.469 30C106.469 27.96 107.169 26.24 108.569 24.84C109.982 23.4267 111.702 22.72 113.729 22.72C115.755 22.72 117.475 23.4267 118.889 24.84C120.302 26.24 121.009 27.96 121.009 30C121.009 32.04 120.302 33.7667 118.889 35.18ZM110.509 33.3C111.362 34.1533 112.435 34.58 113.729 34.58C115.022 34.58 116.095 34.1533 116.949 33.3C117.815 32.4333 118.249 31.3333 118.249 30C118.249 28.6667 117.815 27.5667 116.949 26.7C116.082 25.8333 115.009 25.4 113.729 25.4C112.449 25.4 111.375 25.8333 110.509 26.7C109.642 27.5667 109.209 28.6667 109.209 30C109.209 31.3333 109.642 32.4333 110.509 33.3ZM129.588 37.28C127.495 37.28 125.755 36.58 124.368 35.18C122.995 33.7933 122.308 32.0667 122.308 30C122.308 27.92 122.995 26.1933 124.368 24.82C125.755 23.42 127.495 22.72 129.588 22.72C130.855 22.72 132.022 23.02 133.088 23.62C134.168 24.2067 135.008 25.0067 135.608 26.02L133.228 27.4C132.882 26.7733 132.388 26.2867 131.748 25.94C131.108 25.58 130.388 25.4 129.588 25.4C128.228 25.4 127.128 25.8267 126.288 26.68C125.462 27.5467 125.048 28.6533 125.048 30C125.048 31.3333 125.462 32.4333 126.288 33.3C127.128 34.1533 128.228 34.58 129.588 34.58C130.388 34.58 131.108 34.4067 131.748 34.06C132.402 33.7 132.895 33.2133 133.228 32.6L135.608 33.98C135.008 34.9933 134.168 35.8 133.088 36.4C132.022 36.9867 130.855 37.28 129.588 37.28ZM142.864 29.8L148.244 37H145.064L140.064 30.44V37H137.304V23H140.064V29.18L144.864 23H148.044L142.864 29.8Z"
        fill="#C75FEA"/>
      <rect x="65.5" y="18.5" width="23" height="23" fill="white" stroke="#C75FEA"/>
      <path
        d="M83 30C83 31.1867 82.6481 32.3467 81.9888 33.3334C81.3295 34.3201 80.3925 35.0892 79.2961 35.5433C78.1997 35.9974 76.9933 36.1162 75.8295 35.8847C74.6656 35.6532 73.5965 35.0818 72.7574 34.2426C71.9182 33.4035 71.3468 32.3344 71.1153 31.1705C70.8838 30.0067 71.0026 28.8003 71.4567 27.7039C71.9108 26.6075 72.6799 25.6705 73.6666 25.0112C74.6533 24.3519 75.8133 24 77 24L77 30H83Z"
        fill="#C75FEA"/>
      <defs>
        <clipPath id="clip0_530_273">
          <rect width="48" height="62" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}