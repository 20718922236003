import React from 'react';
import styled from "styled-components";
import WOWLogo from '../../icons/wow'
import MMProLogo from '../../icons/MMProMobileLogo'
import {isMobile} from 'react-device-detect'


import ConnectWalletButton from "../PrivateSale/ConnectWalletButton";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 60px 20px;

  @media screen and (max-width: 1000px) {
    padding: 23px;
  }

  @media screen and (max-width: 760px) { 
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
  }
`

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`

const LogoWrapper = styled.div`
  svg {

  }
`

const Header = () => {
	return (
		<Wrapper>
			<LogosWrapper>
				<LogoWrapper>
					<WOWLogo/>
				</LogoWrapper>
				X
				<LogoWrapper>
					<MMProLogo/>
				</LogoWrapper>
			</LogosWrapper>
			{/*<ConnectWalletButton/>*/}
		</Wrapper>
	);
};

export default Header;
