export default () => (
  <svg width="155" height="33" viewBox="0 0 155 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_504_26)">
      <path
        d="M34.9227 21.8959H32.5268L32.3238 16.8733L29.3188 20.9541H28.9939L25.989 16.8733L25.7047 21.8959H23.3088L23.8773 12.0469H24.5677L29.1158 17.9327L33.6232 12.0469H34.3136L34.9227 21.8959Z"
        fill="white"/>
      <path
        d="M42.6787 15.4602C43.2066 15.9311 43.4909 16.6766 43.4909 17.6968V21.5815C42.1914 21.9346 40.9732 22.1308 39.8768 22.1308C37.4809 22.1308 36.2627 21.3853 36.2627 19.855C36.2627 18.3639 37.4809 17.6576 39.5113 17.6576C39.9174 17.6576 40.4859 17.6968 41.2981 17.8145V17.6576C41.2981 17.1867 41.1762 16.8728 40.892 16.7158C40.6077 16.5589 40.161 16.4804 39.5519 16.4804C38.821 16.4804 38.0494 16.5981 37.2373 16.7943L36.9936 15.264C38.09 14.9893 39.0646 14.8324 39.9986 14.8324C41.2575 14.7931 42.1508 15.0286 42.6787 15.4602ZM39.958 20.5613C40.4047 20.5613 40.8514 20.522 41.2981 20.4828V19.1094C40.7296 19.0309 40.2423 18.9917 39.8362 18.9917C38.9022 18.9917 38.4149 19.2664 38.4149 19.7765C38.4149 20.3258 38.8616 20.5613 39.958 20.5613Z"
        fill="white"/>
      <path
        d="M50.1913 14.9894L49.8258 16.6374C49.2167 16.5197 48.6888 16.4805 48.2015 16.5197C47.9985 16.5197 47.6736 16.5982 47.2675 16.6767V21.8955H45.0747V15.6172C46.3335 15.0679 47.5518 14.7932 48.6888 14.7932C49.3385 14.754 49.8258 14.8324 50.1913 14.9894Z"
        fill="white"/>
      <path
        d="M56.4448 21.8954L54.4144 18.7563H53.6022V21.8954H51.4094V11.1831L53.6022 11.0654V17.1082H54.455L56.4042 14.9893H58.8407L56.1199 17.8538L58.9625 21.9346H56.4448V21.8954Z"
        fill="white"/>
      <path
        d="M61.4395 18.9914C61.6019 19.9723 62.3329 20.4432 63.6323 20.4432C64.282 20.4432 65.0942 20.3255 66.0688 20.1293L66.3124 21.5027C66.0282 21.6596 65.5815 21.7773 65.0536 21.8951C64.5257 22.0128 63.9572 22.0912 63.3887 22.0912C62.1298 22.0912 61.1146 21.7381 60.3431 21.071C59.6121 20.404 59.2061 19.5407 59.2061 18.4813C59.2061 17.4218 59.5715 16.5193 60.3025 15.8522C61.0334 15.1459 61.9674 14.832 63.145 14.832C64.2414 14.832 65.0942 15.1459 65.7845 15.7345C66.4749 16.3624 66.7997 17.1471 66.7997 18.0889C66.7997 18.4028 66.7591 18.7167 66.7185 18.9914H61.4395V18.9914ZM64.6475 17.618C64.6475 17.3041 64.5257 16.9902 64.282 16.7547C64.0384 16.5193 63.6323 16.4016 63.0638 16.4016C62.211 16.4016 61.6425 16.794 61.4395 17.618H64.6475Z"
        fill="white"/>
      <path
        d="M72.9722 20.1684L73.1346 21.6595C72.4037 21.8949 71.6728 22.0126 70.9418 22.0126C70.2921 22.0126 69.7642 21.8164 69.3581 21.4633C68.952 21.1101 68.7896 20.5215 68.7896 19.7368V16.5192H67.6526L67.7744 14.9496H68.7896V13.1839L70.9824 12.9092V14.9496H73.1752L73.0534 16.5192H70.9824V19.5013C70.9824 20.0114 71.1855 20.2076 71.754 20.2076C72.1194 20.2469 72.5255 20.2469 72.9722 20.1684Z"
        fill="white"/>
      <path
        d="M89.5403 21.8959H87.1444L86.9414 16.8733L83.9364 20.9541H83.6115L80.6472 16.8733L80.3629 21.8959H77.967L78.5355 12.0469H79.2259L83.774 17.9327L88.2814 12.0469H88.9718L89.5403 21.8959Z"
        fill="white"/>
      <path
        d="M97.2962 15.4602C97.8241 15.9311 98.1083 16.6766 98.1083 17.6968V21.5815C96.8089 21.9346 95.5906 22.1308 94.4942 22.1308C92.0984 22.1308 90.8801 21.3853 90.8801 19.855C90.8801 18.3639 92.0984 17.6576 94.1288 17.6576C94.5348 17.6576 95.1033 17.6968 95.9155 17.8145V17.6576C95.9155 17.1867 95.7937 16.8728 95.5094 16.7158C95.2252 16.5589 94.7785 16.4804 94.1694 16.4804C93.4384 16.4804 92.6669 16.5981 91.8547 16.7943L91.6111 15.264C92.7075 14.9893 93.6821 14.8324 94.616 14.8324C95.8749 14.7931 96.7683 15.0286 97.2962 15.4602ZM94.5754 20.5613C95.0221 20.5613 95.4688 20.522 95.9155 20.4828V19.1094C95.347 19.0309 94.8597 18.9917 94.4536 18.9917C93.5196 18.9917 93.0323 19.2664 93.0323 19.7765C93.0729 20.3258 93.5196 20.5613 94.5754 20.5613Z"
        fill="white"/>
      <path
        d="M104.809 21.8954L102.778 18.7563H101.966V21.8954H99.7734V11.1831L101.966 11.0654V17.1082H102.819L104.768 14.9893H107.205L104.484 17.8538L107.326 21.9346H104.809V21.8954Z"
        fill="white"/>
      <path d="M110.575 13.7732H108.342V11.6543H110.575V13.7732ZM110.534 21.8957H108.342V14.9896H110.534V21.8957Z"
            fill="white"/>
      <path
        d="M119.468 17.3828V21.8953H117.275V17.8537C117.275 17.3436 117.194 16.9904 117.072 16.7942C116.91 16.598 116.626 16.4803 116.138 16.4803C115.61 16.4803 115.082 16.5588 114.473 16.6765V21.9345H112.281V15.6563C113.621 15.107 114.961 14.8323 116.382 14.8323C118.331 14.793 119.468 15.6955 119.468 17.3828Z"
        fill="white"/>
      <path
        d="M128.321 22.7191C128.321 23.4647 127.955 24.0925 127.184 24.5633C126.412 25.0342 125.397 25.2697 124.097 25.2697C122.96 25.2697 122.026 25.0735 121.377 24.7203C120.686 24.3279 120.362 23.857 120.362 23.2685C120.362 22.6406 120.727 22.1305 121.417 21.6989C121.174 21.4635 121.011 21.1103 121.011 20.7179C121.011 20.2078 121.296 19.7762 121.905 19.423C121.296 18.9522 120.971 18.2851 120.971 17.4611C120.971 16.6371 121.296 15.97 121.905 15.4991C122.514 15.0283 123.326 14.7928 124.341 14.7928C124.788 14.7928 125.235 14.8321 125.641 14.9498C125.925 13.8511 126.615 13.3018 127.671 13.3018H128.158L128.199 14.9498H127.752C127.306 14.9498 126.981 15.146 126.818 15.5384C127.346 16.0092 127.63 16.6371 127.63 17.4219C127.63 18.2459 127.346 18.8737 126.737 19.3446C126.128 19.8154 125.316 20.0901 124.301 20.0901C123.854 20.0901 123.407 20.0509 123.042 19.9331C122.92 20.0116 122.879 20.1293 122.879 20.2863C122.879 20.4825 123.001 20.6002 123.285 20.6394C123.326 20.6394 123.691 20.6787 124.301 20.7179C124.91 20.7964 125.559 20.8356 126.168 20.9141C127.59 21.0711 128.321 21.6597 128.321 22.7191ZM126.128 22.9938C126.128 22.6406 125.884 22.4837 125.316 22.4052L122.879 22.1698C122.636 22.4052 122.473 22.6406 122.473 22.8761C122.473 23.3862 123.042 23.7393 124.219 23.7393C125.478 23.7393 126.128 23.4647 126.128 22.9938ZM123.164 17.4611C123.164 18.1674 123.61 18.599 124.26 18.599C124.991 18.599 125.356 18.1674 125.356 17.4611C125.356 16.7548 124.991 16.4016 124.26 16.4016C123.529 16.3624 123.164 16.794 123.164 17.4611Z"
        fill="white"/>
      <path
        d="M140.341 14.9105C140.341 15.97 139.975 16.8332 139.204 17.5003C138.432 18.1674 137.336 18.5205 135.914 18.5205C135.549 18.5205 135.143 18.5205 134.656 18.4813V21.8951H133.275V12.0853C133.478 12.0853 134.006 12.0461 134.859 12.0461C135.711 12.0068 136.28 12.0068 136.564 12.0068C139.082 12.0068 140.381 12.9486 140.341 14.9105ZM138.919 14.989C138.919 14.3219 138.716 13.8511 138.351 13.5764C137.985 13.3017 137.336 13.1448 136.402 13.1448C136.28 13.1448 135.671 13.184 134.656 13.2232V17.2649C135.183 17.3041 135.63 17.3041 135.996 17.3041C137.945 17.3041 138.919 16.5193 138.919 14.989Z"
        fill="white"/>
      <path
        d="M146.35 14.9497L146.31 16.1269C145.701 16.0484 145.132 16.0484 144.645 16.0877C144.32 16.1269 143.873 16.2054 143.345 16.4016V21.9343H142.005V15.7345C142.655 15.4206 143.264 15.1852 143.752 15.0282C144.239 14.9105 144.807 14.832 145.376 14.832C145.538 14.832 145.863 14.8713 146.35 14.9497Z"
        fill="white"/>
      <path
        d="M148.3 21.0326C147.569 20.3655 147.203 19.5023 147.203 18.4428C147.203 17.4226 147.569 16.5593 148.34 15.8923C149.071 15.186 150.005 14.8721 151.101 14.8721C152.238 14.8721 153.213 15.2252 153.903 15.8923C154.634 16.5593 155 17.4226 155 18.4821C155 19.5023 154.634 20.3655 153.863 21.0326C153.091 21.7389 152.198 22.0528 151.101 22.0528C149.924 22.0528 148.99 21.6997 148.3 21.0326ZM151.101 20.9541C151.832 20.9541 152.442 20.7187 152.929 20.2478C153.376 19.7769 153.619 19.1884 153.619 18.4821C153.619 17.7758 153.376 17.1872 152.929 16.7163C152.482 16.2454 151.873 16.01 151.101 16.01C150.33 16.01 149.721 16.2454 149.274 16.7163C148.827 17.1872 148.584 17.7758 148.584 18.4821C148.584 19.1884 148.827 19.7769 149.274 20.2478C149.761 20.7187 150.371 20.9541 151.101 20.9541Z"
        fill="white"/>
      <path
        d="M15.0249 11.4185V7.33769H14.7812V11.4185H12.9945L10.2738 14.0476V6.19976H8.52764V0H8.28399V6.19976H6.49725V14.0476L3.77653 11.4185H1.98978V7.33769H1.74614V11.4185H0V21.9738H1.74614V27.7027H1.98978V21.9738H3.77653V15.7741L6.49725 18.4031V25.0737H8.28399V33H8.52764V25.0737H10.2738V18.4031L12.9945 15.7741V21.9738H14.7812V27.7027H15.0249V21.9738H16.771V11.4185H15.0249Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_504_26">
        <rect width="155" height="33" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)