import React from 'react';
import styled from "styled-components";
import FooterLinksText from "../../icons/FooterLinksText";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100px;
  background: #040611;
  gap: 20px;
  padding: 40px 120px;
  
  @media screen and (max-width: 1400px) {
    padding: 40px 20px;
  }
`

const FooterLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`

const StyledLink = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
`

const Footer = () => {
  return (
    <Wrapper>
      <FooterLinksText />
      <FooterLinksWrapper>
        <a href="https://drive.google.com/file/d/1dovsWZ8aD6h0iTcwWFQA-GtqXB_KMDX6/view" target={'_blank'}>
          <StyledLink>Whitepaper</StyledLink>
        </a>
        <a href="/assets/Tokenomics.pdf" target={'_blank'}>
          <StyledLink>Tokenomic</StyledLink>
        </a>
      </FooterLinksWrapper>
    </Wrapper>
  );
};

export default Footer;