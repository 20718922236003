export default () => {
  return (
    <svg width="220" height="130" viewBox="0 0 220 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" fillRule="evenodd" clipRule="evenodd"
            d="M220 30L199.823 30C188.875 30 180 38.8751 180 49.8231V50V79.8231V80C180 91.0457 188.954 100 200 100H220V130H180C163.431 130 150 116.569 150 100V103C150 90.1998 139.623 79.8231 126.823 79.8231H0V49.8231H130.177C141.125 49.8231 150 40.948 150 30C150 13.4315 163.431 7.62939e-06 180 7.62939e-06L220 0V30Z"
            fill="#C75FEA"/>
      <path
        d="M80.15 68.0004H96.581C97.0944 77.5009 100.123 86.6945 105.356 94.6404C98.8008 93.6107 92.7716 90.4378 88.2111 85.6178C83.6505 80.7978 80.8158 74.6024 80.15 68.0004ZM80.15 62.0004C80.8158 55.3983 83.6505 49.2029 88.2111 44.3829C92.7716 39.5629 98.8008 36.39 105.356 35.3604C100.123 43.3062 97.0944 52.4998 96.581 62.0004H80.15ZM139.85 62.0004H123.419C122.906 52.4998 119.877 43.3062 114.644 35.3604C121.199 36.39 127.228 39.5629 131.789 44.3829C136.35 49.2029 139.184 55.3983 139.85 62.0004ZM139.85 68.0004C139.184 74.6024 136.35 80.7978 131.789 85.6178C127.228 90.4378 121.199 93.6107 114.644 94.6404C119.877 86.6945 122.906 77.5009 123.419 68.0004H139.85ZM102.59 68.0004H117.41C116.916 76.0988 114.366 83.9377 110 90.7764C105.634 83.9377 103.084 76.0988 102.59 68.0004ZM102.59 62.0004C103.084 53.9019 105.634 46.063 110 39.2244C114.366 46.063 116.916 53.9019 117.41 62.0004H102.59Z"
        fill="#C75FEA"/>
    </svg>
  )
}