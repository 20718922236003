import React from 'react';
import './index.scss';
import styled from "styled-components";

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  margin-bottom: 10px;
  font-weight: 600;
`

const Notification = (props) => {
  const {icon, subtitle, title, shouldDisplay, type} = props;

  const getNotificationType = () => {
    if (type === 'error') {
      return '#fd3566'
    } else if (type === 'default') {
      return '#20D083FF'
    } else if (type === 'warning') {
      return 'orange'
    }
  }

  return (
    <div
      className={`notification ${shouldDisplay ? "shown" : ""}`}
      style={{borderTop: `7px solid ${getNotificationType()}`}}
    >
      <TitleWrapper>
        {icon}
        <div className={"notification-title"}>
          {title}
        </div>
      </TitleWrapper>
      <div className={"notification-body"}>
        {subtitle}
      </div>
    </div>
  );
};

export default Notification;