import React from 'react';
import NftWowText from "../../icons/NftWowText/WowNftText.svg";
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #040611;
  height: 73px;
  overflow: hidden;

  img {
    display: inline-block;
    width: 2918px;
    text-indent: 0;
    animation: marquee 60s linear infinite
  }
  
  @keyframes marquee {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(100%, 0);
    }
  }

  @media only screen and (max-width: 440px) {
    .marquee img {
      animation: none;
      padding-left: 0
    }
  }
`

const NftWowBlackBlock = () => {
  return (
    <Wrapper>
      <img src={NftWowText} alt=""/>
    </Wrapper>
  );
};

export default NftWowBlackBlock;