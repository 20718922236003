import './App.css';
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import TokenWowPurpleBlock from "./components/TokenWowPurpleBlock";
import WhatIsWowToken from "./components/WhatIsWowToken";
import NftWowBlackBlock from "./components/NftWowBlackBlock";
import WhatIsWowNft from "./components/WhatIsWowNft";
import WhyWillItWorkPurpleBlock from "./components/WhyWillItWorkPurpleBlock";
import WhyWillItWork from "./components/WhyWillItWork";
import PartnersAndSponsors from "./components/PartnersAndSponsors";
import NextGenerationBlackBlock from "./components/NextGenerationBlackBlock";
import HowItWork from "./components/HowItWork";
import Footer from "./components/Footer";
import PrivateSale from "./components/PrivateSale";
import Notification from "./ui/Notification";
import {PopupContext, NotificationContext} from "./context";
import React, {useState} from "react";
import useFirstRender from "./hooks/useFirstRender";

const App = () => {
    const [popupOpen, setPopupOpen] = useState(false)

    const [shouldDisplayNotification, setShouldDisplayNotification] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationSubtitle, setNotificationSubtitle] = useState('')


    const [notificationType, setNotificationType] = useState('default')

    const displayNotification = (type, title, subtitle) => {
        setNotificationTitle(title)
        setNotificationSubtitle(subtitle)
        setShouldDisplayNotification(true);
        setNotificationType(type)

        setTimeout(() => {
            setShouldDisplayNotification(false);
        }, 2500);
    };

    return (
        <NotificationContext.Provider value={{displayNotification, shouldDisplayNotification, notificationTitle, notificationSubtitle, notificationType}}>
            <PopupContext.Provider value={{isOpen: popupOpen, setOpen: setPopupOpen}}>
                <Header/>
                {/*<PrivateSale/>*/}
                {/*<TokenWowPurpleBlock/>*/}
                <Introduction/>
                <TokenWowPurpleBlock/>
                <WhatIsWowToken/>
                <NftWowBlackBlock/>
                <WhatIsWowNft/>
                <WhyWillItWorkPurpleBlock/>
                <WhyWillItWork/>
                <PartnersAndSponsors/>
                <NextGenerationBlackBlock/>
                <HowItWork/>
                <Footer/>
            </PopupContext.Provider>
        </NotificationContext.Provider>
    );
}

export default App;
